<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-15 17:20:21
 * @LastEditTime: 2021-05-24 08:43:40
 * @FilePath: /shop_frontend/src/views/goodsEvaluate/evaluateRecord.vue
-->
<template>
  <div>
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="全部"> </a-tab-pane>
      <a-tab-pane key="2" tab="置顶评论" force-render> </a-tab-pane>
      <!-- <a-tab-pane key="3" tab="买家秀"> </a-tab-pane> -->
    </a-tabs>
    <div>
      <a-form-model
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="inline"
      >
        <a-row>
          <a-col span="8">
            <a-form-model-item label="模糊搜索">
              <a-input
                class="w220"
                v-model="form.search"
                placeholder="订单编号/商品名称/手机号"
              />
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="评价星级">
              <a-select
                v-model="form.rating"
                placeholder="评价星级"
                style="width: 220px"
                allowClear
              >
                <a-select-option :value="n" v-for="n in 5" :key="n">
                  {{ n }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="onSubmit"> 筛选 </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="record__table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :row-selection="rowSelection"
        bordered
      >
        <div slot="goods" slot-scope="text, record">
          
          <div class="goods__box">
            <img
              :src="record.picture_url"
              style="
                display: inline-block;
                width: 100px;
                height: 100px;
                margin-right: 10px;
              "
              alt=""
            />
            <div>
              <div>订单编号：{{ record.sales_order }}</div>
              <div>{{ record.goods_name }}</div>
              <div>{{ record.spec_name }}</div>
            </div>
          </div>
        </div>
        <div slot="user" slot-scope="text, record">
          <p>用户名：{{ record.member_name }}</p>
          <p>手机号：{{ record.member_phone }}</p>
        </div>
        <div slot="content" slot-scope="text, record">
          <p>评分：<a-rate v-model="record.rating" disabled /></p>
          <p>评价：{{ record.content }}</p>
        </div>
        <div slot="reply_content" slot-scope="text, record">
          <div v-if="record.reply_content">
            <p>回复：{{ record.reply_content }}</p>
            <p>
              <a-button size="small" @click="deleteReply(record)"
                >删除回复</a-button
              >
            </p>
          </div>

          <a-popconfirm
            v-else
            class="customPop"
            placement="right"
            :icon="null"
            ok-text="确认"
            cancel-text="取消"
            @confirm="confirm(record)"
          >
            <template slot="title">
              <a-textarea v-model="record.replyContent"></a-textarea>
            </template>
            <a-button type="primary" size="small">编写回复</a-button>
          </a-popconfirm>
        </div>
        <div slot="is_anonymous" slot-scope="text, record">
          <p>{{ record.is_anonymous ? "是" : "否" }}</p>
        </div>
        <span slot="action" slot-scope="text, record">
          <div class="action_custom">
            <a-icon type="delete" @click="deleteHandle(record)" />
            <a-icon
              type="arrow-down"
              @click="cancelTop(record)"
              v-if="record.is_top"
            />
            <a-icon type="arrow-up" v-else @click="setTop(record)" />
          </div>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品信息",
    dataIndex: "goods_name",
    key: "goods_name",
    scopedSlots: { customRender: "goods" },
    align: "center",
  },
  {
    title: "用户信息",
    dataIndex: "user",
    key: "user",
    scopedSlots: { customRender: "user" },
    align: "center",
  },
  {
    title: "评价内容",
    dataIndex: "content",
    key: "content",
    scopedSlots: { customRender: "content" },
    align: "center",
  },
  {
    title: "评价回复",
    dataIndex: "reply_content",
    key: "reply_content",
    scopedSlots: { customRender: "reply_content" },
    align: "center",
  },
  {
    title: "评价时间",
    dataIndex: "create_time",
    key: "create_time",
    align: "center",
  },
  {
    title: "匿名评价",
    dataIndex: "is_anonymous",
    key: "is_anonymous",
    scopedSlots: { customRender: "is_anonymous" },
    align: "center",
  },
  // {
  //   title: "评价奖励",
  //   dataIndex: "address",
  //   key: "address",
  // },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

import { mapState } from "vuex";
export default {
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      form: {
        search: "",
        rating: "",
      },
      dataSource: [],
      columns,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      is_top: false,
    };
  },
  computed: {
    ...mapState(["isReload"]),
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
    params() {
      return {
        ...this.form,
        status: 1,
      };
    },
  },
  watch: {
    isReload() {
      this.initTable();
      this.$store.commit("setReload", false);
    },
  },
  created() {
    this.initTable();
  },
  methods: {
    deleteReply(obj) {
      this.$axios.delete(`/evaluations/${obj.id}/reply/`).then((res) => {
        this.$message.success("操作成功");
        this.initTable();
      });
    },
    confirm(obj) {
      console.log(obj, "obj");
      this.$axios
        .post(`/evaluations/${obj.id}/reply/`, { content: obj.replyContent })
        .then((res) => {
          this.$message.success("操作成功");
          this.initTable();
        });
    },
    callback(key) {
      console.log(key, "key");
      if (key === "2") {
        this.is_top = true;
      } else {
        this.is_top = false;
      }
      this.initTable();
    },
    setTop(obj) {
      this.$axios.post(`/evaluations/${obj.id}/set_top/`).then((res) => {
        this.$message.success("操作成功");
        this.initTable();
      });
    },
    cancelTop(obj) {
      this.$axios.post(`/evaluations/${obj.id}/cancel_top/`).then((res) => {
        this.$message.success("操作成功");
        this.initTable();
      });
    },

    initTable() {
      console.log(this.params, "params");
      let params = {};
      if (this.is_top) {
        params.is_top = true;
      } else {
        delete params.is_top;
      }
      this.$axios
        .get("/evaluations/", { params: { ...params, ...this.params } })
        .then((res) => {
          this.dataSource = res.results || [];
          this.pagination.total = res.count || 0;
        });
    },

    onSubmit() {
      this.initTable();
    },
    edit(record) {
      console.log(record);
    },
    deleteHandle(record) {
      console.log(record);
      this.$axios.delete(`/evaluations/${record.id}/`).then((res) => {
        this.$message.success("操作成功");
        this.initTable();
      });
    },
    add(record) {
      console.log(record);
    },
  },
};
</script>

<style lang="less" scoped>
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.record__table {
  padding-top: 10px;
}
.goods__box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
</style>

<style>
.ant-popover-message > .anticon {
  display: none;
}
</style>